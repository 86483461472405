<template>
  <div class="text-center mx-auto" style="max-width: 800px">
    <LocaleChanger />
    <BeginReport v-if="!this.loading" />
  </div>
</template>

<script>
import BeginReport from "../components/BeginReport.vue";
import LocaleChanger from "../components/LocaleChanger.vue";

export default {
  data() {
    return {
      loading: true,
    };
  },
  components: {
    BeginReport,
    LocaleChanger,
  },
  beforeCreate: async function () {
    var url =
      process.env.VUE_APP_VIHJAA_ENDPOINT +
      "/incident/organization" +
      this.$router.currentRoute.path;
    await this.axios
      .get(url)
      .then((r) => this.$store.commit("mapOrganization", r.data.item))
      .then(() => {
        if (
          this.$store.state.organization.groups.length === 0 ||
          this.$store.state.organization.groups === null
        ) {
          if (
            this.$store.state.organization.channelList != null &&
            Object.keys(this.$store.state.organization.channelList).length > 0
          ) {
            this.$store.commit(
              "updateGroupId",
              Object.keys(this.$store.state.organization.channelList)[0]
            );
          } else {
            this.$store.commit(
              "updateChannelId",
              this.$store.state.organization.defaultChannel.uuid
            );
          }
        }
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
        this.$router.push("/");
      });

    if (this.$store.state.organization.groups === null) {
      this.$store.commit(
        "updateChannelId",
        this.$store.state.organization.defaultChannel.uuid
      );
    }
  },
};
</script>

